.Home {
    margin-bottom: 15vh;
}

.Main-title {
    font-family: 'Hammersmith One', sans-serif;
    font-size: 6em;
    color: rgb(120, 120, 120);
    text-shadow: 3px 3px 1px #C2C2C2;
}

.Separator {
    height: 5vh;
}

a {
    margin-inline: 3em;
}